/** imports */
import moment from 'moment'
moment.locale('ja')

export const checkBirthday = (str: string): string => {
  if (str === '' || str === undefined) return ''; // 未入力の場合にはエラー判定しない

    /**
   * front/src/typescripts/validator/lib/birthday.tsにて
   * 行っているバリデーションではmoment.jsライブラリを使用して、
   * 日付文字列をmoment型に変更している。
   * この際、moment.jsをstrict modeで使用しないと
   * 実際の月末を超えていたとしても32未満の日付は、
   * 次の月に加算して解釈される。
   * ex. 2019/11/31→2019/12/01 など
   *
   * 2022年11月21日現在、上記のバリデーションはforgiving modeにて使用されているため、
   * 存在しない日付に対応した追加のバリデーションを行う。
   *
   * https://momentjs.com/guides/
   */
  if (!moment(str, 'YYYY/MM/DD', true).isValid()) return '不正な日付です'

  const inputDate = moment(str)
  const maxYear = moment().subtract(15, 'years')
  const minYear = moment().subtract(100, 'years')

  // 15歳未満は日本では就業不可のためエラーにする
  // https://www.rosei.jp/jinjour/article.php?entry_no=49928
  // 未来の日付の入力もここで防がれる
  const tooYoung = inputDate.isAfter(maxYear)
  if(tooYoung) return '15歳未満の方は登録できません'

  // 100歳以上はさすがに求職してないものとしてエラーにする
  const tooOld = inputDate.isBefore(minYear)
  return tooOld ? '100歳以上の方は登録できません' : ''
}
